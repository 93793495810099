<template>
  <div>
    <el-dialog
      :model-value="value"
      :width="width <= 600 ? '100%' : '70vw'"
      @open="$emit('open')"
      @close="$emit('close')"
    >
      <template #header>
        <span class="dashboard-title">
          {{
            form?.uuid
              ? $t('edit_guide_calendar_event')
              : $t('create_guide_calendar_event')
          }}
        </span>
      </template>
      <ElRow :gutter="20" justify="center" align="middle">
        <ElCol :span="24" :xs="24" :lg="24">
          <ElForm
            require-asterisk-position="right"
            ref="ruleFormRef"
            :rules="inputRules"
            :model="form"
            label-position="top"
          >
            <ElRow :gutter="20">
              <ElCol
                :span="24"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
                v-if="!props.guideUuid"
              >
                <ElFormItem
                  :label="$t('guide')"
                  class="autocomplete-new-entity"
                  :class="form.guide_uuid ? 'autocomplete-redirection' : ''"
                >
                  <el-select-v2
                    :key="getGuideKey()"
                    v-loading="getGuideKey() === 0"
                    v-model="form.guide_uuid"
                    :remote-method="searchGuides"
                    :placeholder="$t('select_guide')"
                    remote
                    filterable
                    autocomplete
                    :fallback-placements="['top', 'bottom']"
                    :popper-options="getSelectPopperOptions()"
                    popper-class="custom-selector"
                    :options="getSearchGuides(form.guide)"
                    style="width: 100%"
                  />
                  <Icons
                    name="24px/group_purple"
                    class="new-entity-button"
                    @click="showNewGuideDialog = true"
                  />
                  <Icons
                    v-if="form.guide_uuid"
                    name="24px/arrow_top_right_purple"
                    class="new-entity-button"
                    @click="
                      newWindowItem('/guides/guides', {
                        uuid: form.guide_uuid
                      })
                    "
                  />
                </ElFormItem>
              </ElCol>
              <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <ElFormItem prop="name" :label="$t('event_name')">
                  <ElInput
                    v-model="form.name"
                    @keydown.enter.prevent="submit(ruleFormRef)"
                  />
                </ElFormItem>
              </ElCol>
              <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                <ElFormItem prop="start_date" :label="$t('start_date')">
                  <ElDatePicker
                    v-model="form.start_date"
                    class="!w-full"
                    type="date"
                    format="DD/MM/YYYY"
                    popper-class="custom-datepicker"
                    :editable="false"
                    :clearable="false"
                    @change="form.start_date = formatDateRaw($event)"
                  />
                </ElFormItem>
              </ElCol>
              <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                <ElFormItem prop="end_date" :label="$t('end_date')">
                  <ElDatePicker
                    v-model="form.end_date"
                    class="!w-full"
                    type="date"
                    format="DD/MM/YYYY"
                    popper-class="custom-datepicker"
                    :editable="false"
                    :clearable="false"
                    :disabled-date="disabledEndDate"
                    @change="form.end_date = formatDateRaw($event)"
                  />
                </ElFormItem>
              </ElCol>
              <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                <ElFormItem prop="start_time" :label="$t('start_time')">
                  <ElTimePicker
                    v-model="form.start_time"
                    class="!w-full"
                    type="datetime"
                    popper-class="custom-timepicker"
                    format="HH:mm"
                    :editable="false"
                    :clearable="false"
                  />
                </ElFormItem>
              </ElCol>
              <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                <ElFormItem prop="end_time" :label="$t('end_time')">
                  <ElTimePicker
                    v-model="form.end_time"
                    class="!w-full"
                    type="datetime"
                    popper-class="custom-timepicker"
                    format="HH:mm"
                    :editable="false"
                    :clearable="false"
                  />
                </ElFormItem>
              </ElCol>
              <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <ElFormItem prop="description" :label="$t('description')">
                  <ElInput
                    v-model="form.description"
                    :autosize="{ minRows: 4, maxRows: 8 }"
                    type="textarea"
                    @keydown.enter.prevent="submit(ruleFormRef)"
                  />
                </ElFormItem>
              </ElCol>
              <ElCol v-if="width > 700" :span="16" :lg="16" />
              <ElCol
                :span="width <= 700 ? 24 : 4"
                :lg="4"
                class="cancel-button"
              >
                <ElButton
                  style="width: 100%"
                  type="secondary"
                  @click="$emit('cancel')"
                >
                  {{ $t('cancel') }}
                </ElButton>
              </ElCol>
              <ElCol
                :span="width <= 700 ? 24 : 6"
                :lg="12"
                class="confirm-button"
              >
                <ElButton
                  :loading="loading"
                  :disabled="loading"
                  type="primary"
                  style="width: 100%"
                  @click="submit(ruleFormRef)"
                >
                  {{ $t('save') }}
                </ElButton>
              </ElCol>
            </ElRow>
          </ElForm>
        </ElCol>
      </ElRow>
      <div class="icon-remove">
        <Icons name="24px/trash_red" @click="showDeleteDialog()">
          {{ $t('remove') }}
        </Icons>
      </div>
    </el-dialog>
    <el-dialog
      v-model="showNewGuideDialog"
      :width="width <= 600 ? '100%' : '70vw'"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_guide') }}
        </span>
      </template>
      <QuickCreateGuideDialog
        @create=";(showNewGuideDialog = false), createGuide($event)"
        @cancel="showNewGuideDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import moment from 'moment'
import useVuelidate from '@vuelidate/core'
import { ElMessageBox } from 'element-plus'
import { required } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_GUIDE_CALENDAR_EVENT from '@/graphql/guide-calendar-events/mutation/createGuideCalendarEvent.gql'
import DELETE_GUIDE_CALENDAR_EVENT from '@/graphql/guide-calendar-events/mutation/deleteGuideCalendarEvent.gql'
import UPDATE_GUIDE_CALENDAR_EVENT from '@/graphql/guide-calendar-events/mutation/updateGuideCalendarEvent.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const props = defineProps({
  value: {
    type: Boolean,
    default: false
  },
  guideUuid: {
    type: String,
    default: ''
  },
  guideCalendarEvent: {
    type: Object,
    default: () => ({})
  }
})

const form = reactive({
  uuid: props?.guideCalendarEvent?.uuid || '',
  guide_uuid: props?.guideUuid || props?.guideCalendarEvent?.guide?.uuid || '',
  name: props?.guideCalendarEvent?.name || '',
  start_date: props?.guideCalendarEvent?.start
    ? moment(props?.guideCalendarEvent?.start).format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD'),
  end_date: props?.guideCalendarEvent?.end
    ? moment(props?.guideCalendarEvent?.end).format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD'),
  start_time: props?.guideCalendarEvent?.start
    ? moment(props?.guideCalendarEvent?.start).format('YYYY-MM-DD HH:mm:ss')
    : moment().format('YYYY-MM-DD HH:mm:ss'),
  end_time: props?.guideCalendarEvent?.end
    ? moment(props?.guideCalendarEvent?.end).format('YYYY-MM-DD HH:mm:ss')
    : moment().add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
  description: props?.guideCalendarEvent?.description || '',
  tour_uuid: props?.guideCalendarEvent?.tour?.uuid || '',
  tour: props?.guideCalendarEvent?.tour || ''
})

onMounted(() => {
  initialGuides()
})

const emits = defineEmits(['refresh'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const showNewGuideDialog = ref(false)
let { containerWidth } = getFormInitialVariables()

const rules = {
  guide_uuid: { required },
  name: { required },
  start_date: { required },
  end_date: { required },
  start_time: { required },
  end_time: { required }
}

const v$ = useVuelidate(rules, form)

const inputRules = reactive({
  name: [
    {
      required: true,
      message: t('name_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  guide_uuid: [
    {
      required: true,
      message: t('guide_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  start_date: [
    {
      required: true,
      message: t('start_date_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  end_date: [
    {
      required: true,
      message: t('end_date_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  start_time: [
    {
      required: true,
      message: t('start_time_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  end_time: [
    {
      required: true,
      message: t('end_time_is_required'),
      trigger: ['blur', 'change']
    }
  ]
})

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    createOrUpdate()
  }
}

const createOrUpdate = async () => {
  const variables = {
    guide_uuid: form.guide_uuid,
    name: form.name,
    start:
      form.start_date && form.start_time
        ? moment(form.start_date).format('YYYY-MM-DD') +
          ' ' +
          moment(form.start_time).format('HH:mm') +
          ':00'
        : '',
    end:
      form.end_date && form.end_time
        ? moment(form.end_date).format('YYYY-MM-DD') +
          ' ' +
          moment(form.end_time).format('HH:mm') +
          ':00'
        : '',
    description: form.description,
    tour_uuid: form.tour_uuid,
    ...(form?.uuid && { uuid: form.uuid })
  }

  loading.value = true
  const mutationType = form.uuid
    ? UPDATE_GUIDE_CALENDAR_EVENT
    : CREATE_GUIDE_CALENDAR_EVENT
  const response = await mutation(mutationType, variables)

  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(
      response.error,
      form.uuid ? 'updateGuideCalendarEvent' : 'createGuideCalendarEvent'
    )
  }

  if (response.result) {
    $showSuccess(t(form?.uuid ? 'edit_entity_success' : 'new_entity_success'))
    emits('refresh')
    loading.value = false
  }
}

const showDeleteDialog = async () => {
  ElMessageBox.confirm(
    t('changes_cannot_be_undone'),
    t('are_you_sure_you_want_to_delete'),
    {
      confirmButtonText: t('delete'),
      cancelButtonText: t('cancel'),
      confirmButtonClass: 'el-button--primary',
      cancelButtonClass: 'el-button--secondary',
      customClass: 'custom-delete-box'
    }
  )
    .then(async () => {
      try {
        loading.value = true
        const response = await mutation(DELETE_GUIDE_CALENDAR_EVENT, {
          uuid: form.uuid
        })
        if (response.error) {
          $showError(response.error, t)
          $sentry(response.error, 'showDeleteDialog')
        }
        if (response.result) {
          $showSuccess(t('delete_entity_success'))
        }
      } catch (err) {
        $showError(err, t)
        $sentry(err, 'showDeleteDialog')
      } finally {
        emits('refresh')
        loading.value = false
      }
    })
    .catch(() => {})
}

const createGuide = (event) => {
  if (event?.uuid) {
    form.guide_uuid = event.uuid
    form.guide = event
    searchGuides(event.first_name, form.guide, null, true)
  }
}
</script>

<style>
.icon-remove {
  position: absolute;
  top: 27px;
  right: 60px;
}
</style>